<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="header_top">
        <headerCerrar @cancelar="cerrarModal" colorIcon="gris" />
      </div>

      <!-- título principal -->
      <div class="recoverPassFormTitle title">
        {{ t("app.recoverPassForm.tit") }}
      </div>

      <div class="recoverPassFormText text_basico">
        {{ t("app.recoverPassForm.text") }}
      </div>

      <div class="recoverPassFormFortalezas">
        <!-- <fortalezaPass
            :passVisitada="passVisitada"
            :errorPassCorta="errorPassCorta"
            :errorPassNumber="errorPassNumber"
            :errorPassSimbol="errorPassSimbol"
            :errorPassMayus="errorPassMayus"
            :errorPassMinus="errorPassMinus"
          /> -->
      </div>

      <div class="recoverPassFormInputs">
        <div class="recoverPassFormInputs1">
          <!-- Pass -->
          <inputBox
            typeInput="textP"
            :placeHolder="t('app.recoverPassForm.contrasena')"
            requerido="*"
            :rut="false"
            :error="errorPass"
            @dispatchChangeData="updatePasswordUno"
            :mensajeError="[
              t('configuracion.data.textos_errores.campoObligatorio'),
              'mínimo 6 caracteres',
            ]"
            @blur="passVisitada = true"
            :mensajeOk="{
              mostrar: mensajeOkPassMostrar,
              texto: t('app.recoverPassForm.passOk'),
            }"
            :borrarDatos="borrarDatos"
            dataInicial=""
            @evaluarData="evaluarData"
          />
        </div>

        <div class="recoverPassFormInputs2">
          <!-- PassConfirm -->
          <inputBox
            typeInput="textP"
            :placeHolder="t('app.recoverPassForm.contrasenaConfirm')"
            requerido="*"
            :rut="false"
            :error="errorPassConfirm"
            @dispatchChangeData="updatePasswordDos"
            :mensajeError="[
              t('configuracion.data.textos_errores.campoObligatorio'),
              'mínimo 6 caracteres',
            ]"
            :mensajeOk="{
              mostrar: mensajeOkConfirmPassMostrar,
              texto: t('app.recoverPassForm.passConfirmOk'),
            }"
            :class="!mensajeOkPassMostrar ? 'disabledComp' : ''"
            :borrarDatos="borrarDatos"
            dataInicial=""
          />
        </div>
      </div>

      <div class="recoverPassFormErrorMessage">
        <span v-if="mensajeErrorChangePass.length > 0">{{
          mensajeErrorChangePass
        }}</span>
      </div>

      <div class="recoverPassFormActionBtn">
        <btnAction
          :textBtn="t('app.comunes.btns.continuar')"
          :disabled="!btnActive"
          @action="registerNewPass"
          colorBack="rojo"
          colorText="blco"
          :estadoBtn="estadoBtn"
          :msg_completar="t('app.comunes.btns.contraseñas_no_coinciden')"
          
        />
      </div>

      <div class="recoverPassFormEtapas">
        <pasosGiro paso="3" totales="3" />
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, alertController } from "@ionic/vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
// import fortalezaPass from "../components/remitente/fortalezaPass2.vue";
import inputBox from "../components/generales/inputBox.vue";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import estas_seguro from "./modales/estas_seguro.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  name: "recoverPassForm",
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    headerCerrar,
    // fortalezaPass,
    inputBox,
    btnAction,
    pasosGiro,
    screen_width_cont,
  },
  data() {
    return {
      errorPass: false,
      errorPassConfirm: false,
      passwordUno: "",
      passwordDos: "",
      mensajeOkPassMostrar: false,
      mensajeOkConfirmPassMostrar: false,
      passVisitada: false,
      errorPassCorta: true,
      // errorPassMayus: true,
      // errorPassMinus: true,
      // errorPassNumber: true,
      // errorPassSimbol: true,
      passwordDosVisitado: false,
      btnActive: true,
      mensajeErrorChangePass: "",
      borrarDatos: false,
    };
  },
  computed: {
    ...mapState(["respForgotPass_sin_log", "widthScreen"]),

    estadoBtn() {
      let aux = "inactive";
      if (
        this.passwordUno != "" &&
        this.passwordDos != "" &&
        this.passwordUno == this.passwordDos
      ) {
        aux = "active";
      }
      return aux;
    },
  },
  methods: {
    ...mapMutations(["limpiarRemitente", "updateBloquearNavegacion", "setshowSpinner"]),
    ...mapActions(["changePassword"]),

    cerrarModal() {
      // this.limpiarRemitente({
      //   ultimas_transacciones: [],
      // });
      // this.$router.push({ name: "logIn", replace: true });
      this.callAbandonar();
    },
    async callAbandonar() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.recoverPassForm.tit_abandonar"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "",
          text: "",
          btnText_1: this.t("app.comunes.btns.salir"),
          btnText_2: "",
          colorIcon: "rojoOscuro",
        },
        cssClass: this.widthScreen < 600 ? [
          "myModalAbandonar",
          "myModalAbandonar_ancho_80",
          "myModalAbandonar_alto_50",
        ] : [
          "myModalAbandonar",
          "myModalAbandonar_ancho_40",
          "myModalAbandonar_alto_40",
        ],
        animated: true,
        backdropDismiss: false,
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          this.$router.push({ name: "logIn", replace: true });
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });
      return modal.present();
    },
    updatePasswordUno(data) {
      this.passVisitada = true;
      this.passwordUno = data;
      this.errorPass = false;
    },
    updatePasswordDos(data) {
      this.passwordDosVisitado = true;
      this.passwordDos = data;
    },
    registerNewPass() {
      this.setshowSpinner(false);
      console.log(this.passwordUno, this.passwordDos);
      let errores = false;

      if (!this.mensajeOkPassMostrar) {
        this.errorPass = true;
        this.passVisitada = true;
        errores = true;
      }
      if (!this.mensajeOkConfirmPassMostrar) {
        this.errorPassConfirm = true;
        errores = true;
      }
      if(!this.passwordUno || !this.passwordDos){
        
        errores = true;
      }

      if (errores) {
        // console.log("1");
        return;
      } else {
        // console.log("Falta controlador");
        this.btnActive = false;

        this.changePassword({
          newPassword: this.passwordUno,
          confirmPassword: this.passwordDos,
          // token: this.codigoChangePass,
        });
      }
    },
    evaluarData() {
      if (!this.mensajeOkPassMostrar && this.passVisitada) {
        this.errorPass = true;
        this.passVisitada = true;
        // errores = true;
      }
      if (!this.mensajeOkConfirmPassMostrar && this.passwordDosVisitado) {
        this.errorPassConfirm = true;
        // errores = true;
      }
    },
    async presentAlert(data) {
      let alert = await alertController.create({
        header: "Error",
        subHeader: data.msg,
        buttons: ['Ok'],
        backdropDismiss: false,
      });
      await alert.present();
    },
  },
  watch: {
    respForgotPass_sin_log() {
      this.mensajeErrorChangePass = "";
      // this.mensajeCodigo = "";
      if (this.respForgotPass_sin_log.status) {
        if (this.respForgotPass_sin_log.resp) {
          this.borrarDatos = !this.borrarDatos;

          this.$router.push({ name: "recoverPassFinalizar", replace: true });
        } else {
          if(this.respForgotPass_sin_log.msj == "ERROR_CLI_027"){
            this.presentAlert({
              msg: this.t(`configuracion.errores_srv.Cliente.${this.respForgotPass_sin_log.msj}`)
            });
          }else if(this.respForgotPass_sin_log.msj == "ERROR_CLI_019"){
            this.presentAlert({
              msg: this.t(`configuracion.errores_srv.Cliente.${this.respForgotPass_sin_log.msj}`)
            });
          }
          
        }
        this.btnActive = true;
      }
    },
    passwordUno() {
      if (this.passwordUno.length > 5) {
        this.errorPassCorta = false;
      } else {
        this.errorPassCorta = true;
      }

      // if (/[A-Z]/.test(this.passwordUno)) {
      //   this.errorPassMayus = false;
      // } else {
      //   this.errorPassMayus = true;
      // }

      // if (/[a-z]/.test(this.passwordUno)) {
      //   this.errorPassMinus = false;
      // } else {
      //   this.errorPassMinus = true;
      // }

      // if (/[0-9]/.test(this.passwordUno)) {
      //   this.errorPassNumber = false;
      // } else {
      //   this.errorPassNumber = true;
      // }

      // if (/[^a-zA-Z0-9&\\;<>]/.test(this.passwordUno)) {
      //   this.errorPassSimbol = false;
      // } else {
      //   this.errorPassSimbol = true;
      // }

      if (
        !this.errorPassCorta
        // &&
        // !this.errorPassMayus &&
        // !this.errorPassMinus &&
        // !this.errorPassNumber &&
        // !this.errorPassSimbol
      ) {
        this.mensajeOkPassMostrar = true;
        if (this.passwordUno == this.passwordDos) {
          this.mensajeOkConfirmPassMostrar = true;
        } else {
          this.mensajeOkConfirmPassMostrar = false;
        }
      } else {
        this.mensajeOkPassMostrar = false;
        this.mensajeOkConfirmPassMostrar = false;
      }

      if (this.passwordDosVisitado) {
        if (this.passwordUno != this.passwordDos) {
          this.errorPassConfirm = true;
        } else {
          this.errorPassConfirm = false;
        }
      }
    },
    passwordDos() {
      if (
        !this.errorPassCorta
        // &&
        // !this.errorPassMayus &&
        // !this.errorPassMinus &&
        // !this.errorPassNumber &&
        // !this.errorPassSimbol
      ) {
        if (this.passwordUno == this.passwordDos) {
          this.mensajeOkConfirmPassMostrar = true;
        } else {
          this.mensajeOkConfirmPassMostrar = false;
        }
      } else {
        this.mensajeOkConfirmPassMostrar = false;
      }

      if (this.passwordUno != this.passwordDos) {
        this.errorPassConfirm = true;
      } else {
        this.errorPassConfirm = false;
      }
    },
  },
  ionViewWillLeave() {
    this.passwordUno = "";
    this.passwordDos = "";
    this.borrarDatos = !this.borrarDatos;
  },
};
</script>

<style>
.recoverPassFormTitle {
  margin-top: 2rem;

  text-align: center;
  color: var(--customA-801B1C);
}
.recoverPassFormText {
  margin-top: 2.5rem;
  /* font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 18px; */
  /* display: flex;
  align-items: flex-end; */
  text-align: justify;
  /* letter-spacing: 0.5px; */
  color: var(--customG-787878);
}
.recoverPassFormFortalezas {
  margin-top: 1rem;
}
.recoverPassFormInputs {
  margin-top: 5.5rem;
}
.recoverPassFormInputs1 {
  margin-top: 1.1rem;
}
.recoverPassFormInputs2 {
  margin-top: 0.8rem;
}
.recoverPassFormErrorMessage {
  margin-top: 1rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 0.6875rem;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: var(--customA-801B1C);
  /* border: 1px solid green; */
}
.recoverPassFormActionBtn {
  margin-top: 1.625rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}
.recoverPassFormEtapas {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}
</style>